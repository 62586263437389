:root {
  --background: gainsboro;
  --element: whitesmoke;
  --backgroundtext: black;
  --elementtext: black;
  --altelementtext: dimgray;
  --buttoncolor: #1976d2;
}

body {
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: var(--backgroundtext);
  font-family: "Roboto";
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}

.filter-search-buttons {
  transform: translateY(2px);
}

.admin-forms .MuiOutlinedInput-root {
  background-color: white;
}

.ingredient-cell {
  width: 120px;
  overflow-wrap: normal;
}

.amount-cell {
  white-space: nowrap;
}

.notonhand {
  color: var(--altelementtext);
  font-style: italic;
}

.centered {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

.heading {
  text-align: center;
  padding: 20px;
}

.flash {
  animation: flash 2500ms;
  font-weight: 700;
}

@keyframes flash {
  0%,
  52%,
  68%,
  84%,
  100% {
    background-color: inherit;
  }
  10%,
  44%,
  60%,
  76%,
  92% {
    background-color: yellow;
  }
}

.cocktail-container {
  --container-width: 100px;
  --goblet-height: var(--container-width);
  --border-thickness: calc(var(--container-width) / 20);
  --border-color: black;
  position: absolute;
  width: var(--container-width);
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(var(--container-width) * -2));
}

.cocktail-stem {
  height: var(--goblet-height);
  width: var(--border-thickness);
  background: var(--border-color);
  transform: translate(calc(var(--container-width) / 2 + var(--border-thickness) / 2), 0);
  border-radius: calc(var(--border-thickness) / 2);
}

.cocktail-base {
  width: 75%;
  height: calc(var(--goblet-height) / 5);
  border: var(--border-thickness) solid black;
  border-radius: 50%;
  transform: translate(
    calc(50% - 37.5% + calc(var(--border-thickness) / 2)),
    calc(var(--goblet-height) / -8)
  );
}

.cocktail-goblet-top {
  --goblet-top-height: calc(var(--goblet-height) / 10);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--goblet-top-height);
  border: var(--border-thickness) solid var(--border-color);
  border-radius: 50%;
  transform: translate(0, calc(var(--goblet-height) - var(--goblet-top-height)));
}

.cocktail-goblet-mask {
  --goblet-mask-translate: calc(var(--container-width) / 20);
  position: absolute;
  top: calc(var(--border-thickness) * -1);
  left: -17%;
  height: calc(var(--goblet-height) + var(--goblet-mask-translate));
  width: calc(var(--container-width) * 1.5);
  background-color: var(--background);
}

.cocktail-goblet {
  height: calc(calc(var(--goblet-height) * 2) - calc(var(--border-thickness) * 2));
  width: calc(var(--container-width) - calc(var(--border-thickness) * 2));
  border: var(--border-thickness) solid transparent;
  border-top: none;
  border-radius: 50%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 0 0 var(--border-thickness) var(--border-color);
  transform: translate(var(--border-thickness));
  overflow: hidden;
}

.cocktail-liquid {
  position: absolute;
  width: 300%;
  right: -25%;
  bottom: 0px;
  height: 50%;
  background: #b57105;
  animation: slosh linear 1s infinite;
  /* got the below mask code from https://css-generators.com/wavy-shapes/ */
  --mask: radial-gradient(52.57px at 50% 71.25px, #000 99%, #0000 101%) calc(50% - 50px) 0/100px
      100%,
    radial-gradient(52.57px at 50% -46.25px, #0000 99%, #000 101%) 50% 25px/100px 100% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

@keyframes slosh {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(37%, 0);
  }
}
